import algoliasearch from 'algoliasearch/lite'

let client;

window.addEventListener("load", (event) => {
  client = algoliasearch(window.ALGOLIA_APP_ID, window.ALGOLIA_KEY_ID);
  loadEvents();
});
function loadEvents () {
  if (window && window.announcekit) {
    $('#announcekit-widget-container').show().css('display', 'flex');
    window.announcekit.push({
      'widget': 'https://announcekit.app/widget/4t3k64',
      'selector': '.announcekit-widget',
      'version': 2
    })
  }

  $('#discoverApps').on('click', showDiscoverApps);
  $('.discover-apps-container').mouseleave(transforArrow)
  $('.container-top-navbar-desktop').mouseleave(transforArrow)
  $('#resourcesMenu').on('click', showDiscoverApps);
  $('#adviceMenu').on('click', showDiscoverApps);
  $('#handle-dropdown').click(handleDropdown)
  $('#dropdown-user-info').click(handleUserDropdown)
  $('.container-top-navbar-desktop').mouseover(closeMegamenuModal) // close megamenu if hover
  $('#div-full-screen-dropdown').click(closeDropdownModal)
  $('#div-full-screen-search').click(manageSearch)
  $('#newsletter-submit-navbar').click(subscribeForm)
  $(window).scroll(menuUpdate).scroll()
  $('#arrow-up-dropdown').hide()
  $('#arrow-up-dropdown-apps').hide()
  $('#arrow-up-dropdown-resources').hide()
  $('#open-search-topnavbar').click(manageSearch)
  $('#newsletter-dropdown-desktop').click(openNewsletterMenu)
  $('#newsletter-submit-topnavbar').on('click', submitNewsletter)
  $('#searcher_two_home_htr_topnavbar').keyup(searchAlgolia)

  $(document).click(handleClickOutsideDiscoverApps);
}

function handleClickOutsideDiscoverApps(event) {
  if ($('.discover-apps-container').is(':visible') &&
      !$(event.target).closest('.simplified-navbar-container, #discoverApps, #resourcesMenu, #adviceMenu').length) {
    hideMenu(event);
  }
}

function manageSearch () {
  if ($('.search-header-wrapper').is(':visible')) {
    $('.search-header-wrapper').slideUp('fast')
    $('#div-full-screen-search').hide()
  } else {
    $('.search-header-wrapper').slideDown('fast')
    $('#div-full-screen-search').show()
    $('#searcher_two_home_htr_topnavbar').focus()
  }
}

function openNewsletterMenu () {
  if ($('.newsletter-container').is(':visible')) {
    $('.newsletter-container').hide()
  } else {
    $('.newsletter-container').show()
  }
}

function submitNewsletter () {
  const url = window.location.href
  const email = $('#newsletter-topnavbar-email').val()
  $.post('/register-neswleter', {
    'email': email,
    'signup_url': url
  })
    .done(function () {
      $('#alertSuccess_htr').show().delay(4000).hide(0)
    })
    .fail(function (e) {
      let $errorText = invalidEmailFormatText(e)
      $('#alertError_htr')
        .text($errorText)
        .show()
        .delay(4000)
        .hide(0)
    })
}
function showDiscoverApps (e) {
  const subMenuTarget = e.currentTarget.id
  if ($('.discover-apps-container').is(':hidden')) {
    showMenu(e);
  } else if (($('#discoverContainer').is(':visible') &&  subMenuTarget === 'discoverApps' )
        || ($('#resourcesContainer').is(':visible') && subMenuTarget === 'resourcesMenu' ) 
        || ($('#adviceContainer').is(':visible') && subMenuTarget === 'adviceMenu')) {
          hideMenu(e);
  }
  
  if (subMenuTarget === 'discoverApps') {
    $('#arrow-down-dropdown-apps').addClass('arrow-transform-hover')
    $('#arrow-down-dropdown-resources').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-advice').removeClass('arrow-transform-hover')
    $('#discoverDescription').removeClass('hide-menu')
    $('#resourceDescription').addClass('hide-menu')
    $('#adviceDescription').addClass('hide-menu')
    $('#discoverContainer').removeClass('hide-menu')
    $('#resourcesContainer').addClass('hide-menu')
    $('#adviceContainer').addClass('hide-menu')
  } else if (subMenuTarget === 'resourcesMenu') {
    $('#arrow-down-dropdown-apps').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-resources').addClass('arrow-transform-hover')
    $('#arrow-down-dropdown-advice').removeClass('arrow-transform-hover')
    $('#resourceDescription').removeClass('hide-menu')
    $('#discoverDescription').addClass('hide-menu')
    $('#adviceDescription').addClass('hide-menu')
    $('#resourcesContainer').removeClass('hide-menu')
    $('#discoverContainer').addClass('hide-menu')
    $('#adviceContainer').addClass('hide-menu')
  } else if (subMenuTarget === 'adviceMenu') {
    $('#arrow-down-dropdown-apps').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-resources').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-advice').addClass('arrow-transform-hover')
    $('#adviceDescription').removeClass('hide-menu')
    $('#discoverDescription').addClass('hide-menu')
    $('#resourceDescription').addClass('hide-menu')
    $('#adviceContainer').removeClass('hide-menu')
    $('#discoverContainer').addClass('hide-menu')
    $('#resourcesContainer').addClass('hide-menu')
  }
}

function transforArrow (e) {
  const target = e.target.id
  $('#arrow-down-dropdown-resources').removeClass('arrow-transform-hover')
  $('#arrow-down-dropdown-apps').removeClass('arrow-transform-hover')
  $('#arrow-down-dropdown-advice').removeClass('arrow-transform-hover')

  if (target === '.discover-apps-container') {
    $('#arrow-down-dropdown-resources').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-apps').removeClass('arrow-transform-hover')
    $('#arrow-down-dropdown-advice').removeClass('arrow-transform-hover')
  } else if (target === '.container-top-navbar-desktop') {
    if ($('.discover-apps-container').is(':hidden')) {
      $('#arrow-down-dropdown-resources').removeClass('arrow-transform-hover')
      $('#arrow-down-dropdown-apps').removeClass('arrow-transform-hover')
      $('#arrow-down-dropdown-advice').removeClass('arrow-transform-hover')
    }
  }
}

function showMenu (e) {
  if (e.target.className === 'discover-apps-container-shadow') {
    return false
  } else {
    $('.simplified-dim-background').show()
    $('.discover-apps-container').show()
    $('.discover-apps-container').addClass('discover-apps-container-show')
    $('.discover-apps-container').addClass('discover-apps-container-show-active')
    setTimeout(function () {
      $('.discover-apps-container').removeClass('discover-apps-container-show')
    }, 40)
    setTimeout(function () {
      $('.discover-apps-container').removeClass('discover-apps-container-show-active')
    }, 250)
  }
}

function hideMenu (e) {
  if (e.target.className === 'discover-apps-container-shadow') {
    return false
  } else {
    $('.simplified-dim-background').hide()
    $('.discover-apps-container').addClass('discover-apps-container-hide')
    $('.discover-apps-container').addClass('discover-apps-container-hide-active')
    setTimeout(function () {
      $('.discover-apps-container').removeClass('discover-apps-container-hide')
    }, 100)
    setTimeout(function () {
      $('.discover-apps-container').removeClass('discover-apps-container-hide-active')
    }, 200)
    setTimeout(function () {
      $('.discover-apps-container').hide()
    }, 100)
  }
}

function handleDropdown () {
  if ($('#dropdown-container-categories').is(':visible')) {
    $('#dropdown-container-categories').hide()
  } else {
    $('#dropdown-container-categories').show()
  }
}

function handleUserDropdown () {
  if ($('#dropdown-user-info-container').is(':visible')) {
    $('#dropdown-user-info').removeClass('clicked-dropdown-user-info')
    $('#dropdown-user-info-container').hide()
    $('#div-full-screen-dropdown').hide()
  } else {
    $('#dropdown-user-info').addClass('clicked-dropdown-user-info')
    $('#dropdown-user-info-container').show()
    $('#div-full-screen-dropdown').show()
  }
}

function closeMegamenuModal () {
  $('#megamenu-categories-container').hide()
  $('#div-full-screen-megamenu').hide()
  const activeCategory = $('.is-active-category')
  $(activeCategory).removeClass('is-active-category')
  $(activeCategory).removeClass('clicked-category-department-menu')
}

function closeDropdownModal () {
  $('#dropdown-user-info-container').hide()
  $('#div-full-screen-dropdown').hide()
  $('#dropdown-user-info').removeClass('clicked-dropdown-user-info')
}

function menuUpdate () {
  let scroll = $(document).scrollTop()
  const heightBanner = $('.htr-banner-fix').height() || 45
  if (scroll > heightBanner) {
    $('#header-fixed').addClass('active')
    $('.categories-list-container').fadeOut(200)
    $('#arrow-down-dropdown').show()
    $('#arrow-up-dropdown').hide()
    $('#header-fixed').removeClass('positionRelative')
    $('.htr-banner-fix').css('display', 'none')
  } else {
    $('.htr-banner-fix').css('display', 'flex')
    $('.categories-list-container').fadeIn(200)
    $('#arrow-down-dropdown').hide()
    $('#arrow-up-dropdown').show()

    if (!window.Laravel.header.alwaysActive) {
      $('#header-fixed').removeClass('active')
    } else {
      $('#header-fixed').addClass('positionRelative')
    }
  }
}

function setResultIntoDom (result, query, sectionsInDom, namesInDom, idSearch) {
  for (var k = 0; k < sectionsInDom.length; k++) {
    if (result[sectionsInDom[k]]) {
      var html = `<div class="search-item">
        <div class="search-item__header">
          ${namesInDom[k]}
          <span>(${result[sectionsInDom[k]].nbHits} results)</span>
          <a href="/s/${query}/${sectionsInDom[k]}">View more</a>
        </div>
        <ul class="search-item__body">`

      for (var j = 0; j < result[sectionsInDom[k]].hits.length; j++) {
        if (sectionsInDom[k] === 'products') {
          html += `<li class="search-item__body-product">
          <a href="${result[sectionsInDom[k]].hits[j].url}">
          <img src="${result[sectionsInDom[k]].hits[j].logo ? result[sectionsInDom[k]].hits[j].logo : '/images/company-default-logo.png'}"></img>
          <div>
            <span class="search-product__title">${result[sectionsInDom[k]].hits[j].title}</span>
            <span class="search-product__category">${result[sectionsInDom[k]].hits[j].category.title}</span>
          </div>
          <input type="hidden" value="${result[sectionsInDom[k]].hits[j].rating}"></input>
          `
          if (result[sectionsInDom[k]].hits[j].rating) {
            const rating = Number(result[sectionsInDom[k]].hits[j].rating)
            html += '<ul class="rating">'
            for (var i = 1; i <= 5; i++) {
              html += '<li>'
              if (rating >= i) {
                html += '<img src="/images/star-full-blue.svg" width="18"></img>'
              } else if (i - rating <= 0.5) {
                html += '<img src="/images/star-half-blue.svg" width="18"></img>'
              } else {
                html += '<img src="/images/star-empty-blue.svg"></img>'
              }
              html += '</li>'
            }
            html += '</ul>'
          }
          html += '</a>'
        } else {
          html += `<li>
          <a href="${result[sectionsInDom[k]].hits[j].url}">
            <span>${result[sectionsInDom[k]].hits[j].title}</span>
          </a>`
        }
      }
      html += '</li></ul>'
      html += '</div>'
      $(`#${idSearch}${sectionsInDom[k]}`).empty()
      $(`#${idSearch}${sectionsInDom[k]}`).append(html)
    }
  }
}

function indexName (key) {
  return window.ENVIRONMENT + '_' + key
}

function handleResponse (results) {
  return results.reduce(
    (accumulator, item) => {

      if (item.index === indexName("categories_index"))
        accumulator.categories = item;
      if (item.index === indexName("products_index"))
        accumulator.products = item;
      if (item.index === indexName("posts_index"))
        accumulator.articles = item;
      if (
        [
          indexName("resources_index"),
          indexName("buyers_guides_index"),
        ].includes(item.index)
      ) {
        if (!Object.keys(accumulator.resources).length) {
          accumulator.resources = item;
        } else {
          accumulator.resources.nbHits += item.nbHits;
          accumulator.resources.hits = [
            ...accumulator.resources.hits,
            ...item.hits,
          ];
          accumulator.resources.hits = accumulator.resources.hits.slice(
            0,
            3
          );
        }
      }

      return accumulator;
    },
    { categories: {}, articles: {}, resources: {}, products: {} }
  );
}

// key pressed in the search input
function searchAlgolia (e) {
  if (e.key == 'Escape' || e.keyCode == 27) {
    $('#drowdown_searcher_two_htr_topnavbar').slideUp('fast')
    $('#div-full-screen-search').hide()
    return false
  }
  var value = e.target.value
  if (e.key === 'Enter') {
    window.location.href = `/s?query=${value}`
    return false
  }
  const sectionsInDom = ['categories', 'products', 'articles', 'resources']
  const namesInDom = ['Categories', 'Products', 'Articles', 'Free Resources & Guides']
  if (value.length < 3) {
    sectionsInDom.forEach((section) => {
      $(`#${section}`).empty()
    })
    return false
  } else {
    const searchInAlgolia = [
      {
        indexName: indexName('categories_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('products_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('posts_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('resources_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      },
      {
        indexName: indexName('buyers_guides_index'),
        query: value,
        params: {
          hitsPerPage: 3
        }
      }
    ]
    client.multipleQueries(searchInAlgolia).then(function ({results}) {
      const result = { ...handleResponse(results) };
      let search = $('#drowdown_searcher_two_htr_topnavbar')
      $(search).css({ 'visibility': 'visible' })
      $(search).fadeIn(100)
      if (!$('.search-header-wrapper').is(':visible')) {
        $('.search-header-wrapper').slideDown('fast')
      }
      let fullScren = $('#div-full-screen-search')
      $(fullScren).css({ 'display': 'block' })
      setResultIntoDom(result, value, sectionsInDom, namesInDom, 'searcher_two_home_htr_topnavbar')
    }).catch(function (err) {
      console.log(err)
      if (err) throw err
    })
  }
}

function subscribeForm (event) {
  const url = window.location.href
  if ($(event.target).is('#newsletter-submit-navbar')) {
    const email = $('#newsletter-email-navbar').val()
    $.post('/register-neswleter', {
      'email': email,
      'signup_url': url
    })
      .done(function () {
        $('#alertSuccess_htr-navbar').show().delay(4000).hide(0)
      })
      .fail(function (e) {
        let $errorText = invalidEmailFormatText(e)
        $('#alertError_htr-navbar')
          .text($errorText)
          .show()
          .delay(4000)
          .hide(0)
      })
  }
}
function invalidEmailFormatText (error)
{
  return error.responseJSON?.message ?
    error.responseJSON.message : 'Invalid email format'
}

export { submitNewsletter, searchAlgolia, subscribeForm, invalidEmailFormatText, showDiscoverApps, handleClickOutsideDiscoverApps };
